@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "./styles/_vars.scss";

* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Noto Sans";
}

h1 {
  font-family: "Noto Sans";
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
}

h2 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

h3 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

button {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  height: 40px;
  color: white;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

button:hover {
  cursor: pointer;
  opacity: 0.8;
}

input {
  height: 40px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 9px 8px;
  background-color: #fff;
  color: #464646;
  &::placeholder {
    color: #aaaaaa;
  }
}

.buttons {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.tabsCategory {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.semiHedline {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.primaryText {
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: black;
}

.secondaryText {
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.H1 {
  font-family: "Noto Sans";
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
}

.H2 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.H3 {
  font-family: "Noto Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.body1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.body2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.skeleton {
  background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.Toastify {
  position: absolute;
}
