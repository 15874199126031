.containerModal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111000;
  background: rgb(36 36 36 / 40%);
  .modal {
    width: fit-content;
    max-width: calc(100% - 40px);
    height: fit-content;
    background: white;
    z-index: 111000;
    border-radius: 16px;

    .content {
      max-width: 700px;
      padding: 32px 40px;
    }
  }
}
