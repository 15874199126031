@import "../../styles/vars";

.recoverPage {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(../../../public/images/Background.svg) no-repeat center center
    fixed;
  background-size: cover;
  .recoverPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    .recoverPageTitle {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      p {
        text-align: center;
        color: $secondary-font;
      }

      h1 {
        color: $primary_font;
        text-align: center;
      }
    }

    .recoverPassword {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .recoverPasswordContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 64px;
        width: 100%;
        position: relative;

        .recoverPasswordSuccess {
          position: absolute;
          color: $accent-color;
          font-size: 12px;
          bottom: -20px;
        }

        .recoverPasswordInputs {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 52px;

          .recoverPasswordInput {
            position: relative;
            display: flex;
            gap: 8px;
            width: 100%;

            .recoverPasswordError {
              position: absolute;
              bottom: -19px;
              color: $error-color;
              font-size: 12px;
            }

            input {
              width: 100%;
            }

            label {
              position: absolute;
              top: -25px;
              width: 200px;
            }
          }
        }

        button {
          width: 108px;
          font-size: 14px;
        }
      }
      .twaPageTimer {
        width: 108px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #f5fff7;
        font-family: "Noto Sans";
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        height: 40px;
        color: $accent-color;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .recoverPagee {
    background: url(../../../public/images/BackgroundMobile.svg) no-repeat
      center center fixed;
    background-size: cover;
    .recoverPageContainer {
      padding: 60px 32px;
      .recoverPasswordContainer {
        flex-direction: column;
        .twoFaButtons {
          width: 100%;
          .twaPageTimer {
            width: 100%;
          }
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
