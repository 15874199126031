.accountTitle {
  text-transform: uppercase;
}

.dashboardTableSkeleton {
  width: 100%;
  height: 500px;
}
.showMoreBtn {
  color: #21a038;
}

.dashboardItemLine {
  border: none;
  background-color: transparent;
}

.saveButton {
  width: 100%;
}

.popOver {
  position: fixed;
}
