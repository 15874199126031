@import "../../styles/vars";

.serviceItem {
  background-color: white;
  box-shadow: 0px 4px 15px 0px #8990d226;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 320px;
  gap: 12px;
  color: $text-font;

  .serviceItemLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    a {
      width: 100%;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 124px;
        background: transparent;
        color: $accent-color;
        border: 1px solid $accent-color;
      }
    }

    .serviceItemLink {
      display: flex;
      gap: 6px;
      .link {
        color: $accent-color;
      }
    }
    .serviceItemLinksButton {
      width: 124px;
    }
  }
  h1 {
    color: $primary_font;
  }
  .textContainer {
    display: flex;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    font-weight: 500;
    .header {
      display: flex;
      align-items: center;
      gap: 12px;
      .titleText {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }
  .button {
    display: flex;
    gap: 16px;
  }

  .boldText {
    font-weight: bold;
  }
}

.imageSceleton {
  width: 82px;
  height: 82px;
  min-width: 82px;
  min-height: 82px;
  border-radius: 100%;
  background: linear-gradient(90deg, #ececec87, #ffffff, #ffffff);

  animation: loading 1s infinite;
}
@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
