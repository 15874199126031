@import "../../styles/vars";

.containerMenuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0 8px 12px;
  border-radius: 8px;
  color: $primary_font;
  .containerTitles {
    display: flex;
    flex-direction: column;
    .containerTitle {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .minus {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .priceAcc {
    color: #21a038;
    font-weight: 600;
    font-size: 18px;
  }
  .priceActive {
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
  .iconsMenu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .priceTitle {
    display: flex;
    align-items: center;
    .price {
      color: $accent-color;
    }
  }
}
.activeMenuItem {
  background: var(--green-gradient);
}
.subTitle {
  color: #aaaaaa !important;
}
.skeletonPrice {
  width: 50px;
  height: 44px;
}
.activeColor {
  color: white !important;
}

.menuBarSkeleton {
  width: 100%;
  height: 40vh;
}
