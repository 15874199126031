@import "../../styles/vars";
.login {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(../../../public/images/Background.svg) no-repeat center center
    fixed;
  background-size: cover;
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 500px;

    gap: 36px;
    .logoContainerTitle {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $primary_font;
    }
    .loginContainerForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .loginContainerInputs {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        .serverError {
          color: $error-color;
        }
        .loginContainerInput {
          display: flex;
          flex-direction: column;
          gap: 8px;
          position: relative;

          .loginContainerInputEye {
            position: absolute;
            top: 50%;
            right: 8px;

            &:hover {
              cursor: pointer;
            }
          }

          .fieldErrors {
            color: $error-color;
            position: absolute;
            font-size: 12px;
            bottom: -18px;
          }
        }
      }

      .loginRecover {
        display: flex;
        gap: 4px;
        .loginRecoverText {
          color: $secondary-font;
        }
        .loginRecoverLink {
          color: $accent-color;
        }
      }
    }
    .loginContainerRegistration {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      padding: 16px 32px;
      margin-top: 12px;

      background-color: white;
      box-shadow: 0px 4px 15px 0px #8990d226;
      border-radius: 16px;

      .loginContainerRegistrationText {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 60%;
        .loginRecoverLink {
          color: $accent-color;
        }
      }
      .loginContainerRegistrationButton {
        a {
          display: flex;
          align-items: center;
          color: white;
          gap: 8px;
          padding: 9px 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login {
    background: url(../../../public/images/BackgroundMobile.svg) no-repeat
      center center fixed;
    background-size: cover;
    .loginContainer {
      padding: 60px 32px;

      .loginContainerRegistration {
        margin-top: 120px;
      }
    }
  }
}
