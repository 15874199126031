.mainLayout {
  position: relative;
  width: 100vw;
  height: 100vh;
  flex-grow: 1;
}

.wrapperMainLayout {
  display: flex;
  flex: row;
  height: 100%;
  width: 100%;
}

.childrenLayout {
  position: absolute;
  left: 260px;
  width: calc(100% - 260px);
  height: 100%;
  background: #fafafa;
  box-sizing: border-box;
  overflow-x: hidden;
}
.containerLayout {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 500px) {
  .childrenLayout {
    width: 100vw;
    position: static;
    overflow: scroll;
  }
}
