.containerFooter {
  min-height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 2px solid #f3f3f3;
  z-index: 2;
  margin: 0px 40px;
  p {
    color: #aaaaaa;
  }
  .textFirmName {
    background: linear-gradient(
      88.98deg,
      #83da4e 39.18%,
      #21a038 49.07%,
      #54acb8 58.12%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}
