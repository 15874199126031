.containerRate {
  padding: 16px 24px 20px 24px;
  height: 124px;
  border-radius: 16px;
  background: white;
  .containerTitle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 16px;
  }
  .widgetSkeleton {
    width: 100%;
    height: 44px;
  }
  .wrapperContainerProcents {
    display: flex;
    align-items: center;
    gap: 24px;

    .containerProcents {
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding: 8px 34px;
      border-radius: 16px;

      span {
        margin-right: 5px;
      }
    }
  }
}
