@import "../../styles/vars";

.containerRateAndNews {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .containerNews {
    padding: 22px 24px;
    background: white;
    border-radius: 16px;
    width: 513px;

    .containerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      .linkTitle {
        color: $accent-color;
      }
    }
  }

  .containerNewsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    .newsSkeleton {
      width: 100%;
      height: 326px;
    }
    .containerNewOne {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .dateColor {
      color: $secondary-font;
    }
    .linkCircle {
      width: 32px;
      height: 32px;
      background: $accent-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .titleNewsColor {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
      cursor: pointer;
      position: relative;
      color: $primary_font;
    }
  }
}

.noDataAvailableTitle {
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
