.textSkeleton {
  width: 100px;
  height: 24px;
  background: linear-gradient(90deg, #ffffff, #ececec87, #ffffff);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 14px;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
