@import "../../styles/vars";

.newsArticle {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .articleTitle {
    display: flex;
    align-items: center;
    gap: 22px;

    .articleTitleButton {
      display: flex;
      align-items: center;
      gap: 8px;

      padding: 8px 24px;
      background: transparent;
      color: $accent-color;
    }
  }

  .articleItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .articleTitleId {
      color: $accent-color;
    }

    h1 {
      text-transform: uppercase;
    }

    .articleItemText {
      max-width: 100%;
      max-height: 100%;
    }

    .articleItemDate {
      display: flex;
      gap: 5px;
      color: $secondary-font;
    }
  }

  .newsArticleSceleton {
    width: 100%;
    height: 30vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #f0f0f0;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);

    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
