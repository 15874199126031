.newsPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 40px;

  .newsPageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .newsPageSceleton {
    width: 100%;
    height: 80vh;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);

    animation: loading 1.5s infinite;
  }

  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(253, 187, 45, 0) 100%
    );
  }
}

@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
