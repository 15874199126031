@import "../../styles/vars";

.containerItemBlock {
  width: 100%;
  height: 124px;
  background: white;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #8990d226;
  .containerContentItemBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contentContainer {
    display: flex;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .containerAdditional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .linkTitle {
      color: $accent-color;
    }
  }
  .highlight {
    color: $accent-color;
  }
  .titleNormal {
    color: $primary_font;
  }
  .titleColored {
    color: $accent-color;
  }
}
