@import "../../styles/vars";

.footer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  border-top: 2px solid $border-colors;
  padding-top: 26px;
  margin-left: 12px;
  .containerTgChannel {
    display: flex;
    align-items: center;
    gap: 11px;

    .subscripteLink {
      font-weight: 700;
      font-size: 12px;
      color: #006c9c;
      padding: 6px 8px;
      background: #dff4f9;
      border-radius: 6px;
    }
  }
  a {
    color: $primary_font;
  }
}
