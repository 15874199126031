@import "../../styles/vars";

.newsItem {
  width: 100%;
  box-shadow: 0px 4px 15px 0px #8990d226;
  padding: 32px 40px;
  border-radius: 16px;
  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .newsItemButton {
    background: transparent;
    color: $accent-color;
  }
  .newsItemLeft {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .containerTitle {
      display: flex;
      align-items: center;
      gap: 16px;
      .importantTitle {
        color: white;
        font-size: 12px;
        padding: 4px 16px;
        background: linear-gradient(
          88.58deg,
          #83da4e 2.42%,
          #21a038 51.09%,
          #54acb8 95.68%
        );
        border-radius: 8px;
      }
    }
    .newsItemText {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    p {
      color: $text-font;
    }

    h2 {
      text-transform: uppercase;
      span {
        color: $accent-color;
      }
    }

    .dateTekst {
      color: #aaaaaa;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
