.profilePage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 40px;
  .profileTitle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
