.modalOverlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.5s ease-in-out;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #fff;
  padding: 30px;
  border-radius: 16px;
  width: 720px;
  min-height: 40vh;

  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.progressBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepIndicator {
  padding: 10px;
  background: #ddd;
  border-radius: 8px;
  font-weight: bold;
  width: 80px;
  text-align: center;
}

.stepIndicator.active {
  background: #34b4d0;
  color: white;
}

.modalContainer {
  width: 100%;
  display: flex;
  text-align: start;
  .singleStep {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 32px;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 78px;
  }
}

.step {
  display: none;
}

.topUpNetworkList {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 21px;
    height: 18px;
  }
}

.step.activeStep {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 100%;
}

.modalItemsGap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

input {
  height: 40px;
}

.sideSteps {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.stepRound {
  border-radius: 100%;
  background-color: #21a038;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.textInactive {
  color: #aaaaaa;
}

.stepInactive {
  border-radius: 100%;
  background-color: #aaaaaa;
  min-height: 36px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.stepTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stepLine {
  margin: 16px 0;
  width: 2px;
  background-color: #f3f3f3;
  height: 100%;
}
.continueBtn {
  width: 114px;
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  :last-child {
    color: #232323;
    border: 1px solid #23232340;
    padding: 10px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
  }
}

.buttonsStepsContainer {
  display: flex;
  gap: 21px;
  :last-child {
    color: #464646;
  }
}

.closeBtn {
  position: absolute;
  bottom: 32px;
  right: 32px;
  color: #232323;
  border: 1px solid #23232340;
  padding: 10px 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

label {
  text-align: left;
  font-weight: bold;
}

.accountFormContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .deleteButton {
    position: absolute;
    padding: 0;
    max-width: 16px;
    height: 16px;

    left: -45px;
    background-color: transparent;
    box-shadow: none;
    color: #aaaaaa;
    &:hover {
      box-shadow: none;
    }
  }
}

.thirdStepContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .thirdStepTransferInformation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .thirdStepContainerInput {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
      max-height: 52px;

      h1 {
        font-size: 24px;
      }
    }

    .thirdStepFee {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 14px;
      .thirdStepServiceFee {
        display: flex;
      }

      .thirdStepFeeContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 70%;
      }

      span {
        display: flex;
        align-items: center;
      }
    }
    .thirdStepAttention {
      display: flex;
      gap: 8px;
      flex-direction: column;
      padding: 16px 24px;
      background-color: #ffe2e2;
      border-radius: 16px;
      .thirdStepAttentionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .thirdStepAttentionTitleButton {
          transform: rotate(180deg);
        }

        button {
          height: fit-content;
          transition: transform 0.3s ease;
        }
      }
      .thirdStepAttentionText {
        span {
          color: #ff4d4d;
          font-weight: 700;
        }
      }
    }
  }
}
