.containerModal {
  background: white;
  border-radius: 16px;
  width: 100%;

  .containerInputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;

    .inputsModal {
      width: 100%;
    }
  }
  .containerButtons {
    display: flex;
    flex-direction: column;
    gap: 18px;
    .containerInviteAndText {
      display: flex;
      flex-direction: column;
      gap: 18px;
      align-items: flex-start;
    }
    .submitBtn {
      padding: 7px 21px;
      color: white;
      background: linear-gradient(
        88.58deg,
        #83da4e 2.42%,
        #21a038 51.09%,
        #54acb8 95.68%
      );
      border: none;
      border-radius: 8px;
    }
    .btnCloseModal {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 7px 21px;
        border: 1px solid #23232340;
        color: #232323;
        background: white;
        font-weight: 700;
      }
    }
  }
  .textModalDescription {
    width: 100%;
    margin-bottom: 18px;
  }
}
