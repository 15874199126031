.tabs {
  display: flex;
  gap: 32px;
  padding: 8px 32px;
  width: fit-content;
  background-color: white;
  box-shadow: 0px 4px 15px 0px #8990d226;
  border-radius: 16px;
  button {
    transition: all 0s;
  }
  .tab {
    background: transparent;
    color: #232323;

    &.active {
      background: linear-gradient(
        88.58deg,
        #83da4e 2.42%,
        #21a038 51.09%,
        #54acb8 95.68%
      );
      -webkit-background-clip: text;
      color: transparent;

      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          88.58deg,
          #83da4e 2.42%,
          #21a038 51.09%,
          #54acb8 95.68%
        );
      }
    }
  }
}
