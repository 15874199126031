.profileSettigns {
  background-color: white;
  padding: 26px 24px;
  border-radius: 16px;
  color: #232323;
  box-shadow: 0px 4px 15px 0px #8990d226;

  .flexRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 2px solid #f3f3f3;
    width: 100%;
    min-height: 64px;
    .profileChangeLogout {
      background: none;
      color: #ff4d4d;
      border: 1px solid#ff4d4d;
      width: 110px;
    }
  }

  .profileChangeContainer {
    display: flex;
    align-items: center;
    .textSkeleton {
      width: 100px;
      height: 24px;
      background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
      border-radius: 16px;
    }
  }
}

.changeProfileButtons {
  color: #21a038;
  margin-left: 16px;
  height: 100%;
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #21a038;
  padding: 9px 0;
  border-radius: 16px;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media screen and (max-width: 1200px) {
  .profileSettigns {
    .flexRow {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 500px) {
  .profileSettigns {
    .flexRow {
      display: flex;
      flex-direction: column;
      text-align: start;
    }
  }
}
