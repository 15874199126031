.accountsPage {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .accountPageHeader {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .accountsBlockInfoContainer {
    display: flex;
    gap: 20px;
  }

  .tableContainer {
    box-shadow: 0px 4px 15px 0px #8990d226;
    border-radius: 16px;
  }
}
