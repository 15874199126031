.userStatusTitle {
  padding: 4px 36px;
  border-radius: 4px;
}

.addAccountInTable {
  color: white;
  background: linear-gradient(
    88.58deg,
    #83da4e 2.42%,
    #21a038 51.09%,
    #54acb8 95.68%
  );
  padding: 4px 16px;
  border: none;
}

.popupTitleBlock {
  display: flex;
  align-items: center;
  gap: 47px;

  button {
    color: #21a038;
    border: none;
    background: transparent;
  }
}

.modalAddMember {
  .mantine-Modal-modal {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    max-width: 700px;
    min-width: 500px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .mantine-Modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }

  .mantine-Modal-header {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  .mantine-Modal-body {
    padding-top: 0;
  }
}

.skeletonTable {
  width: 100%;
  height: 80vh;
}
