@import "../../styles/vars";

.resetPage {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(../../../public/images/Background.svg) no-repeat center center
    fixed;
  background-size: cover;
  .resetPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    gap: 64px;

    .resetPageTitle {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      p {
        text-align: center;
        color: $secondary-font;
      }

      h1 {
        color: $primary_font;
        text-align: center;
      }
    }
    .resetPasswordForm {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;
      .resetPasswordInputs {
        display: flex;
        flex-direction: column;
        gap: 56px;
        .resetPasswordInput {
          position: relative;
          display: flex;
          gap: 8px;
          width: 100%;

          .loginContainerInputEye {
            position: absolute;
            right: 8px;
            top: 8px;
            &:hover {
              cursor: pointer;
            }
          }

          .error {
            color: $error-color;
            position: absolute;
            font-size: 12px;
            bottom: -18px;
          }

          input {
            width: 100%;
          }

          label {
            position: absolute;
            top: -25px;
            width: 200px;
          }
        }
      }
    }
    .loginContainerRegistration {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      padding: 16px 32px;
      margin-top: 12px;

      background-color: white;
      box-shadow: 0px 4px 15px 0px #8990d226;
      border-radius: 16px;

      .loginContainerRegistrationText {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 60%;
        .loginRecoverLink {
          color: $accent-color;
        }
      }
      .loginContainerRegistrationButton {
        a {
          display: flex;
          align-items: center;
          color: white;
          gap: 8px;
          padding: 9px 16px;
        }
      }
    }
  }
}
