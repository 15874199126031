.tableAccountName {
  display: flex;
  flex-direction: column;

  .tableAccountsNameTitle {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 5px;
  }

  .tableAccountsNameIban {
    color: #464646;
  }
}
