.contentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
  .containerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    .subTitle {
      text-align: center;
    }
    .containerSubtitleText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
    p {
      margin: 0px;
    }
  }

  .containerButtons {
    display: flex;
    align-items: center;
    gap: 16px;

    .cancelButton {
      background: transparent;
      color: #232323;
      font-weight: 600;
      border: 1px solid #23232340;
      padding: 9px 28px;
    }
    .activeBtn {
      padding: 9px 38px;
    }
  }
}
