.modalIssue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 5;

  .modalIssueContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    position: relative;
    background-color: white;
    box-shadow: 0px 4px 15px 0px #8990d226;
    width: 740px;
    height: 290px;
    border-radius: 16px;
    padding: 23px 32px;

    .modalIssueContainerButton {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-top: 16px;
      .modalIssueContainerIssueButton {
        background: linear-gradient(
          88.58deg,
          #83da4e 2.42%,
          #21a038 51.09%,
          #54acb8 95.68%
        );
        padding: 7px 36px;
      }
      span {
        font-weight: 600;
        color: #232323;
      }
    }
    p {
      color: #464646;
    }

    .modalIssueContainerExitButton {
      position: absolute;
      color: #232323;
      bottom: 23px;
      right: 32px;
      width: 85px;
      border: 1px solid #23232340;
    }
  }
}
