.profileSettigns {
  border-radius: 16px;
  color: #232323;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .profileSettingsTop {
    background-color: white;
    padding: 26px 24px;
    box-shadow: 0px 4px 15px 0px #8990d226;
    border-radius: 16px;
  }

  .flexRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 2px solid #f3f3f3;
    width: 100%;
    min-height: 64px;
    .profileChangeLogout {
      background: none;
      color: #ff4d4d;
      border: 1px solid#ff4d4d;
      width: 110px;
    }
  }
  .limitnConditionItems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;

    .limitnConditionItem {
      background-color: white;
      box-shadow: 0px 4px 15px 0px #8990d226;
      padding: 16px 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .limitnConditionItemWithdraw {
        font-size: 12px;
        font-weight: 500;
        span {
          color: #ff4d4d;
        }
      }

      .limitnConditionItemWithdrawContent {
        display: flex;
        align-items: center;
        gap: 8px;
        :first-child {
          font-size: 30px;
          font-weight: 700;
          color: #232323;
        }
        :last-child {
          color: #aaaaaa;
          font-size: 12px;
          font-weight: 500;
        }
      }

      h2 {
        text-transform: uppercase;
      }
      span {
        color: #21a038;
      }
      .limitnConditionItemContents {
        display: flex;
        float: auto;
        flex-direction: column;
        gap: 16px;
        .limitnConditionItemContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          :last-child {
            font-weight: 500;
          }
        }
      }
    }
  }

  .limitnConditionAttention {
    background-color: #ffe2e2;
    box-shadow: 0px 4px 15px 0px #8990d226;
    padding: 16px 24px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: 14px;

    .limitnConditionAttentionSpan {
      background-color: #ff4d4d;
      color: white;
      padding: 2px 8px;
      margin-left: 12px;
      border-radius: 4px;
      font-weight: 500;
    }

    span {
      color: #232323;

      font-weight: 700;
    }
  }
}
