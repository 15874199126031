.mainWrapper {
  padding: 32px 40px;

  p {
    margin-bottom: 24px;
  }
  .containerTable {
    padding: 16px 24px;
    background-color: white;
    border-radius: 16px;
  }
}
