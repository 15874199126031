.dashboardMain {
  padding: 30px 40px 36px 40px;
  .dashboardTitleContainer {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 7px;
    margin-bottom: 36px;

    .reloadIcon {
      cursor: pointer;
    }
    .title {
      color: black;
    }
  }
  .centerMainContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
}
